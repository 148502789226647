app.directive('addVideoModal', function($rootScope, $ngModal) {
    return {
        scope: true,
        restrict: 'E',
        templateUrl: '/partials/components/add-video.html',
        replace: true,
        link: function($scope, elem, attrs, controller) {

            $scope.page;
            $scope.cell;
            $scope.url;

            $scope.$on("ADD_VIDEO", function(e, params) {
                $scope.page = params.page;
                $scope.cell = params.cell;
                $ngModal.openModal("add-video-modal");
            });

            $scope.keydown = function(e) {
                if (e.keyCode == 13 && e.metaKey) {
                    $scope.insertVideo();
                }
                if (e.keyCode == 27) {
                    close();
                }
            };

            $scope.onChange = function() {

                var video_id = "";
                var belong = "";
                var url = $scope.url;

                $scope.videoID = undefined;
                url = url.split('&')[0];

                if (url.search(/youtube/i) > 0) {
                    video_id = parseYoutubeId(url);
                    belong = 'youtube';
                } else if (url.search(/vimeo/i) > 0) {
                    var result = url.match(/^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/);
                    video_id = result[5];
                    belong = 'vimeo';
                } else {
                    $scope.isNotSupportFormatError = true;
                    return;
                }
                $scope.belong = belong || undefined;
                $scope.videoID = video_id || undefined;
            };

            /*
			* Tested URLs:
			var url = 'http://youtube.googleapis.com/v/4e_kz79tjb8?version=3';
			url = 'https://www.youtube.com/watch?feature=g-vrec&v=Y1xs_xPb46M';
			url = 'http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#';
			url = 'http://youtu.be/Ab25nviakcw';
			url = 'http://www.youtube.com/watch?v=Ab25nviakcw';
			url = '<iframe width="420" height="315" src="http://www.youtube.com/embed/Ab25nviakcw" frameborder="0" allowfullscreen></iframe>';
			url = '<object width="420" height="315"><param name="movie" value="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US" type="application/x-shockwave-flash" width="420" height="315" allowscriptaccess="always" allowfullscreen="true"></embed></object>';
			url = 'http://i1.ytimg.com/vi/Ab25nviakcw/default.jpg';
			url = 'https://www.youtube.com/watch?v=BGL22PTIOAM&feature=g-all-xit';
			url = 'BGL22PTIOAM';
			*/
            function parseYoutubeId(url) {
                var ID = '';
                url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                if (url[2] !== undefined) {
                    ID = url[2].split(/[^0-9a-z_\-]/i);
                    ID = ID[0];
                } else {
                    ID = url;
                }
                return ID;
            }

            function insertVideo(cell, belong, videoID, url) {
                delete cell.properties;
                angular.extend(cell, new VideoCell({
                    belong: belong,
                    videoID: videoID,
                    url: url
                }));
            };

            $scope.insertVideo = function() {

                var cell = $scope.cell,
                    originalCell = angular.copy($scope.cell),
                    belong = $scope.belong,
                    videoID = $scope.videoID,
                    url = $scope.url;

                insertVideo(cell, belong, videoID, url);
                undoManager.add({
                    undo: function() {
                        angular.extend(cell, originalCell);
                    },
                    redo: function() {
                        insertVideo(cell, belong, videoID, url);
                    }
                });

                close();
            };

            function close() {
                $ngModal.closeModal("add-video-modal");
                $scope.url = "";
            };
        }
    };
});
