/**
 * Created by Tinny Wang on 2017/9/1.
 */

app.directive('tableDisplay', function () {
	return {
		scope: {
			usedData: '=usedData',
			cols: '=cols'
		},
		restrict: 'EA',
		replace: true,
		templateUrl: '/partials/components/data-table.html',
		controllerAs: 'dtCtrl',
		controller: function ($scope, $filter, NgTableParams) {
			$scope.tableParams = null;
			var unbindDataWatch = createWatcher();

			$scope.$on("TABLE_RESET", function (events, params) {
				$scope.usedData = params.data;
				$scope.tableParams.settings({
					dataset: $scope.usedData
				});
			});

			$scope.$on("TABLE_FILTER", function (events, params) {
				var filterFields = params.filterFields,
					filterVal = params.filterVal;

				var filterOption = filterFields && filterFields.length > 0;
				
				filterOption = !filterOption ? filterVal : (function (obj) {
					var hasVal = false, re, testVal;
					filterFields.forEach(function (unionKey) {
						re = new RegExp('.*' + filterVal + '.*', "gi");
						if(unionKey.includes(':')){
							testVal = getArrayPropVal(obj, unionKey);
						}else{
							testVal = getPropVal(obj, unionKey);
						}
						hasVal = hasVal ? hasVal : re.test(testVal);
					});
					return hasVal;
				});
				
				var filterData = $filter('filter')($scope.usedData, filterOption) || $scope.usedData;
				$scope.tableParams.settings({
					dataset: filterData
				});


			});
			
			function getArrayPropVal(obj, unionKey) {
				var keys = unionKey.split('.'),
					arrProp = keys[0].split(':')[0],
					vals = obj[arrProp];
				vals = _.map(vals, keys[1]);
				return vals.join(' ');
			}
			
			function getPropVal(obj, unionKey) {
				var keys = unionKey.split('.'),
					val = obj;
				keys.forEach(function (key) {
					val = val[key];
				});
				return val;
			}

			function createUsingFullOptions(dataset) {
				var initialParams = {
					count: 10 // initial page size
				};
				var initialSettings = {
					// page size buttons (right set of buttons in demo)
					counts: [],
					// determines the pager buttons (left set of buttons in demo)
					paginationMaxBlocks: 13,
					paginationMinBlocks: 2,
					dataset: dataset
					// getData: function (params) {
					// 	console.log(params.url());
					// 	return PublisherFactory.info('598448269ea3681155a5482a').then(function (resp) {
					// 		console.log(resp);
					// 		var list = [resp.data.administrator, resp.data.otherAdministrator];
					// 		params.total(list.length);
					// 		return list;
					// 	});
					// }
				};
				var tableParams = new NgTableParams(initialParams, initialSettings);

				tableParams.data = dataset;
				// temp.count.length = 2;
				// console.log(temp);
				return tableParams;
			}

			function createWatcher() {
				return $scope.$watch("usedData", function (newDataSet, oldValue) {

					if (newDataSet) {
						if ($scope.tableParams) {
							$scope.tableParams.reload().then(function (data) {
								if (data.length === 0 && $scope.tableParams.total() > 0) {
									$scope.tableParams.page($scope.tableParams.page() - 1);
									$scope.tableParams.reload();
								}
							});
						} else {
							$scope.tableParams = createUsingFullOptions(newDataSet);
						}
					}
				}, true);
			}

		},
		link: function (scope, elem, attrs, controller) {

		}
	};
});
