app.directive('renameAudioModal', function($rootScope, $ngModal){
	return {
		scope: true,
		restrict: 'E',
		templateUrl: '/partials/components/rename-audio.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			$scope.cell;
			$scope.audio;

			$scope.$on("RENAME_AUDIO", function (e, params) {
				$scope.cell = params.cell;
				$scope.audio = params.audio;
				$scope.copy = angular.copy(params.audio);
				$ngModal.openModal("rename-audio-modal");
			});

			$scope.keydown = function (e) {
				if(e.keyCode == 13) {
					$scope.rename();
				}
				if(e.keyCode == 27) {
					close();
				}
			};

			$scope.rename = function () {
				var originName = $scope.audio.name;
				$scope.audio.name = $scope.copy.name;
				undoManager.add({
                    undo: function() {
                        $scope.audio.name = originName;
                    },
                    redo: function() {
                        $scope.audio.name = $scope.copy.name;
                    }
                });
				close();
			};

			function close () {
				$ngModal.closeModal("rename-audio-modal");
				$scope.cell = undefined;
				$scope.audio = undefined;
			};
		}
	};
});