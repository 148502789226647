app.directive('cellEmpty', function($rootScope, Upload) {
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-empty.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			// 插入文字
			function insertText (cell, content) {
				delete cell.properties;
				angular.extend(cell, new RichTextCell({
					html: content
				}));
			};

			$scope.insertText = function (content) {
				var cell = $scope.cell,
					originalCell = angular.copy($scope.cell),
					content = content || '<p><strong><span style="font-size:48px">點按可新增標題</span></strong></p><p><span style="font-size:24px">按一下新增文字</span></p>';

				insertText(cell, content);
				undoManager.add({
	                undo: function() {
	                    angular.extend(cell, originalCell);
	                },
	                redo: function() {
	                    insertText(cell, content);
	                }
	            });
			};

			function insertPhotos (cell, properties) {
				delete cell.properties;
				angular.extend(cell, new AlbumCell({
					images: properties.images || []
				}));
			};
			
			// 插入圖片
			$scope.insertPhotos = function ($files) {
				console.log("You select:");
				console.info($files);

				var cell = $scope.cell,
					originalCell = angular.copy($scope.cell),
					_URL = window.URL || window.webkitURL,
					images = [];

				$files.forEach(function ($file) {
					images.push({
						url: _URL.createObjectURL($file),
						uploading: true
					});
				});
				
				insertPhotos(cell, {
					images
				});

				undoManager.add({
	                undo: function() {
	                    angular.extend(cell, originalCell);
	                },
	                redo: function() {
	                    insertPhotos(cell, {
							images
						});
	                }
	            });
			};
			
			// 插入影片
			$scope.insertVideo = function () {
				$rootScope.$broadcast("ADD_VIDEO", {
					page: $scope.page,
					cell: $scope.cell
				});
			};
			
			// 插入連結
			$scope.insertLink = function () {
				console.log("insertLink");
				$rootScope.$broadcast("ADD_LINK", {
					page: $scope.page,
					cell: $scope.cell
				});
			};
			
			// 插入嵌入內容
			$scope.insertEmbed = function () {
				console.log("insertEmbed");
				$rootScope.$broadcast("ADD_EMBED", {
					page: $scope.page,
					cell: $scope.cell
				});
			};

		}
	};
});

app.directive('cellEmptyThumbnail', function($rootScope, Upload) {
	return {
		// scope: {
		// 	cell: "=cell",
		// 	page: "=page",
		// },
		restrict: 'E',
		templateUrl: '/partials/components/cell-empty-thumbnail.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
		}
	};
});