/**
 * Created by Tinny Wang on 2017/10/5.
 */

app.directive('chartDisplay', function ($compile) {
	
	var getTemplate = function(chartId) {
		return '<div id="'+chartId+'" style="height: 300px; width: 100%; margin: 0 auto"></div>'
	};
	
	return {
		scope: {
			chartId: '=chartId',
			chart: '=chart'
		},
		restrict: 'A',
		link: function (scope, element, attrs) {
			var el = $compile(getTemplate(scope.chartId))(scope);
			element.replaceWith(el);

			var chart = new Highcharts.chart(scope.chartId, scope.chart);
			
			scope.$on('CHART_SET_DATA_' + scope.chartId, function (e, params) {
				var seriesId = params.seriesId,
					data = params.data;
				// chart.series[seriesId].setData(data, true);
				chart.series[seriesId].update({
					pointIntervalUnit: 'day',
					pointStart: params.pointStart,
					data: data
				}, true);
			});
			
			scope.$on('CHART_UPDATE_' + scope.chartId, function (e, params) {
				var seriesId = params.seriesId,
					data = params.data;
				chart.series[seriesId].update({
					pointStart: params.pointStart,
					data: data
				}, true);
			});
		}
	};
});
