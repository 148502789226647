
app.directive('cellAlbum', function($rootScope){
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-album.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			function switchPhoto (photo1, photo2) {
	        	var temp = angular.copy(photo1);
	        	photo1.url = photo2.url;
	        	photo1.annotation = photo2.annotation;
				photo1.stretch = photo2.stretch;
	        	photo2.url = temp.url;
	        	photo2.annotation = temp.annotation;
				photo2.stretch = temp.stretch;
	        };

	        $scope.openPhoto = function (image) {
	        	var images = $scope.cell.properties.images,
	        		idx = images.indexOf(image);
	        	$rootScope.$broadcast("OPEN_GALLERY", {
	        		images: images,
	        		index: idx
	        	});
	        };

			$scope.onPhotoDrop = function (event, ui) {
        		var photo = $scope.draggingPhoto,
        			images = $scope.cell.properties.images,
        			from = $(ui.draggable).index(),
        			dest = $(event.target).index();

    			switchPhoto(images[from], images[dest]);
	            undoManager.add({
	                undo: function() {
	                    switchPhoto(images[dest], images[from]);
	                },
	                redo: function() {
	                    switchPhoto(images[from], images[dest]);
	                }
	            });
		    };

		    $scope.onDragPhotoStart = function (event, ui) {
		        var index = $(event.currentTarget).index();
		        $scope.draggingPhoto = $scope.cell.properties.images[index];
		    };

			$scope.goto = function ($index) {
				$(elem).find(".template").eq(0).animate({"scrollLeft" : $index * 960}, 200, 'swing');
			};

			$rootScope.removeImage = function (cell, image) {
				var images = cell.properties.images,
					index = images.indexOf(image);

				images.splice(index, 1);
				undoManager.add({
	                undo: function() {
	                    images.splice(index, 0, image);
	                },
	                redo: function() {
	                    images.splice(index, 1);
	                }
	            });
			};
		}
	};
});

app.directive('cellAlbumView', function($rootScope){
	return {
		restrict: 'E',
		templateUrl: '/partials/components/cell-album-view.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
			$scope.openPhoto = function (image) {
	        	var images = $scope.cell.properties.images,
	        		idx = images.indexOf(image);
	        	$rootScope.$broadcast("OPEN_GALLERY", {
	        		images: images,
	        		index: idx
	        	});
	        };
		}
	};
});


app.directive('cellAlbumThumbnail', function(){
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-album-thumbnail.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {}
	};
});
