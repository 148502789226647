app.directive('editVideoModal', function($rootScope, $ngModal){
	return {
		scope: true,
		restrict: 'E',
		templateUrl: '/partials/components/edit-video.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			$scope.page;
			$scope.cell;
			$scope.url;

			$scope.$on("EDIT_VIDEO", function (e, params) {
				$scope.page = params.page;
				$scope.cell = params.cell;
				$scope.url = $scope.cell.properties.url;
				$scope.onChange();
				$ngModal.openModal("edit-video-modal");
			});

			$scope.keydown = function (e) {
				if(e.keyCode == 13 && e.metaKey) {
					$scope.editVideo();
				}
				if(e.keyCode == 27) {
					close();
				}
			};

			$scope.onChange = function () {

				var video_id = "";
		        var belong = "";
		        var url = $scope.url;

		        $scope.videoID = undefined;
		        url = url.split('&')[0];

		        if (url.search(/youtube/i) > 0) {
		            var result = url.match(/(.*)watch\?v\=(.*)/);
		            video_id = result[2];
		            belong = 'youtube';
		        } else if (url.search(/vimeo/i) > 0) {
		            var result = url.match(/^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/);
		            video_id = result[5];
		            belong = 'vimeo';
		        } else {
		            $scope.isNotSupportFormatError = true;
		            return;
		        }
		        $scope.belong = belong || undefined;
		        $scope.videoID = video_id || undefined;
			};

			function editVideo (cell, belong, videoID, url) {
				delete cell.properties;
				angular.extend(cell, new VideoCell({
					belong: belong,
					videoID: videoID,
					url: url
				}));
			};

			$scope.editVideo = function () {
				
				var cell = $scope.cell,
					originalCell = angular.copy($scope.cell),
					belong = $scope.belong,
					videoID = $scope.videoID,
					url = $scope.url;

				editVideo(cell, belong, videoID, url);
				undoManager.add({
	                undo: function() {
	                    angular.extend(cell, originalCell);
	                },
	                redo: function() {
	                    editVideo(cell, belong, videoID, url);
	                }
	            });

				close();
			};

			function close () {
				$ngModal.closeModal("edit-video-modal");
				$scope.url = "";
			};
		}
	};
});