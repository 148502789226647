app.directive('cell', function($rootScope, $timeout, Upload){
	return {
		restrict: 'A',
		link: function($scope, elem, attrs, controller) {
			$scope.$watch("cell", function (cell, oldValue) {
				if (cell == oldValue) return;
				$rootScope.$broadcast('$$rebind::refresh');
				if ($rootScope.dirtyCells.indexOf(cell) === -1) {
					$rootScope.dirtyCells.push(cell);
				}
			}, true);

			function insertPhotos (cell, properties) {
				delete cell.properties;
				angular.extend(cell, new AlbumCell({
					images: properties.images || []
				}));
			};

			$rootScope.uploadFiles = function (cell, $files) {
				var currentImages = [];
				if (cell.properties && cell.properties.images) {
					currentImages = cell.properties.images;
				}
				if (currentImages.length + $files.length > 9) {
					alert("相簿最多只能放置 9 張圖片");
					return;
				}

				var originalCell = angular.copy(cell),
					_URL = window.URL || window.webkitURL,
					images = [];

				if (cell.properties && cell.properties.images) {
					images = cell.properties.images || [];					
				}

				$files.forEach(function ($file) {

					var image = {
						url: _URL.createObjectURL($file),
						uploading: true
					};

					images.push(image);
					$rootScope.uploadQueue.push(image);
					Upload.upload({
			            url: 'api/cell/upload-image',
			            data: {file: $file},
			        }).then(function (resp) {
			        	// 上傳成功
			        	image.url = resp.data.Location;
			        	image.uploading = false;
			        	delete image.progress;
			        	var idx = $rootScope.uploadQueue.indexOf(image);
			        	$rootScope.uploadQueue.splice(idx, 1);
			        }, function (resp) {
			        	// 錯誤處理
			        	image.url = undefined;
			        	image.uploading = false;
			        	delete image.progress;
			        	var idx = $rootScope.uploadQueue.indexOf(image);
			        	$rootScope.uploadQueue.splice(idx, 1);
			        }, function (evt) {
			        	image.progress = parseInt(100.0 * evt.loaded / evt.total);
			        	console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt.config.data.file.name);
			        });

				});
				
				insertPhotos(cell, {
					images
				});

				undoManager.add({
	                undo: function() {
	                    angular.extend(cell, originalCell);
	                },
	                redo: function() {
	                    insertPhotos(cell, {
							images
						});
	                }
	            });
			};
		}
	};
});
