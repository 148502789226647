app.directive('cellIframe', function($rootScope) {
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-iframe.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
			$scope.editLink = function () {
				$rootScope.$broadcast("EDIT_LINK", {
					page: $scope.page,
					cell: $scope.cell
				});
			};
		}
	};
});