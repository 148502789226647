app.directive('cellRichText', ['$rootScope', function($rootScope) {
    return {
        scope: {
            cell: "=cell",
            page: "=page",
            currentIndex: "=currentIndex",
            index: '@'
        },
        restrict: 'E',
        templateUrl: '/partials/components/cell-rich-text.html',
        replace: true,
        link: function($scope, elem, attrs, controller) {


            $scope.options = {
                toolbar: 'full',
                toolbar_full: [
                    { name: 'styles', items: ['FontSize', 'Font', '-', 'TextColor', 'BGColor', '-', 'Bold', 'Italic', 'Underline', 'Strike', 'Blockquote', '-', 'Link', 'Unlink', '-', 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'RemoveFormat', 'vertical'] },
                ],
                fillEmptyBlocks: true,
                forcePasteAsPlainText: true,
                allowedContent: true,
                disableNativeSpellChecker: true,
                BrowserContextMenuOnCtrl: true,
                uiColor: '#FAFAFA',
                height: '400px',
                width: '100%',
                skin: 'moono-lisa',
                colorButton_enableMore: false,
                colorButton_colors: 'EB4A4A,EB5E28,F6BC3A,8BC34A,4993C4,94658E,4D4D50,FFFFFF', //83CACF
                fontSize_sizes: '16/16px;18/18px;20/20px;24/24px;28/28px;32/32px;36/36px;40/40px;48/48px;54/54px;60/60px;66/66px;72/72px;80/80px;88/88px;96/96px;',
                font_names: '預設字體/inherit;新細明體/PMingLiU;微軟正黑體/Microsoft JhengHei;Arial;Times New Roman;Verdana;Abril Fatface;Aller Display;Lato;Lora;Open Sasn;Playfair Display;Raleway;Varela Round;',
                fontSize_defaultLabel: '20',
                removePlugins: 'magicline,maximize,resize,liststyle,tabletools,scayt,menubutton,contextmenu',
                extraPlugins: 'sharedspace,vertical',
                // 共用 Toolbar 插件設定
                sharedSpaces: {
                    top: 'editor-ckeditor-space',
                }
            };

            $scope.richTextChange = function(cell, oldValue) {

                var oldHTML = oldValue,
                    newHTML = cell.properties.html;

                undoManager.add({
                    undo: function() {
                        $scope.cell.properties.html = oldHTML;
                    },
                    redo: function() {
                        $scope.cell.properties.html = newHTML;
                    }
                });
            };

            document.addEventListener('SETCELLVERTICAL', function() {

                if ($scope.currentIndex == $scope.index) {
                    $scope.changeVertical($scope.cell, $scope.cell.properties.vertical);
                }
            }, false);

            $scope.changeVertical = function(cell, oldValue) {
                $scope.cell.properties.vertical = !$scope.cell.properties.vertical || false;
                var oldHTML = oldValue,
                    newHTML = $scope.cell.properties.vertical;
                undoManager.add({
                    undo: function() {
                        $scope.cell.properties.vertical = oldHTML;
                    },
                    redo: function() {
                        $scope.cell.properties.vertical = newHTML;
                    }
                });
            }


            $("body").on("mousewheel", ".cell .vertical", function(e, delta) {
                $(this).scrollLeft(this.scrollLeft + (-delta * 5));
                e.preventDefault();
            });



        }
    };
}]);





app.directive('cellRichTextView', function() {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/cell-rich-text-view.html',
        replace: true,
        link: function($scope, elem, attrs, controller) {}
    };
});

app.directive('cellRichTextThumbnail', function() {
    return {
        scope: {
            cell: "=cell",
            page: "=page",
        },
        restrict: 'E',
        templateUrl: '/partials/components/cell-rich-text-thumbnail.html',
        replace: true,
        link: function($scope, elem, attrs, controller) {}
    };
});
