app.directive('page', function($rootScope, Upload, CellFactory) {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/page.html',
        replace: false,
        link: function($scope, elem, attrs, controller) {
            $scope.allCell = "";
            $scope.$watch("page.layout", function(page, oldValue) {
                $rootScope.$broadcast('$$rebind::refresh');
                if (page == oldValue) return;
                if ($rootScope.dirtyPages.indexOf($scope.page) === -1) {
                    $rootScope.dirtyPages.push($scope.page);
                }
            });

            function importImage(cell, result) {

            };
            
            // 快速匯入圖片
            $rootScope.openQuickImportImage = function(cell) {

                $rootScope.$broadcast("SHOW_SMART_INPUT_IMAGE", {
                    cell: cell,
                    callback: function(type, result) {

                        if (cell.properties.images.length >= 9) {
                            alert("相簿最多只能放置 9 張圖片");
                            return;
                        }

                        var image = {
                            name: result.name,
                            url: result.url,
                            stretch: result.stretch,
                        };
                        // 上傳 url
                        CellFactory.uploadImageFromUrl(image.url).then(function(data) {
                            if (!data.data.error) {
                                image.url = data.data;
                            }
                        });
                        cell.properties.images.push(image);
                        undoManager.add({
                            undo: function() {
                                var idx = cell.properties.images.indexOf(image);
                                cell.properties.images.splice(idx, 1);
                            },
                            redo: function() {
                                cell.properties.images.push(image);
                            }
                        });
                    }
                });
            };


            // 開啟匯入工具
            $rootScope.openQuickImport = function(cell) {

                $rootScope.$broadcast("SHOW_SMART_INPUT", {
                    cell: cell,
                    callback: function(type, result) {
                        switch (type) {
                            case "image":

                                var originalCell = angular.copy(cell),
                                    image = {
                                        name: result.name,
                                        url: result.url,
                                        stretch: result.stretch,
                                    };

                                // 上傳 url
                                CellFactory.uploadImageFromUrl(image.url).then(function(data) {
                                    if (!data.data.error) {
                                        image.url = data.data;
                                    }
                                });

                                insertImage(cell, {
                                    images: [image]
                                });

                                undoManager.add({
                                    undo: function() {
                                        angular.extend(cell, originalCell);
                                    },
                                    redo: function() {
                                        insertImage(cell, {
                                            images: [{
                                                name: result.name,
                                                url: result.url,
                                                stretch: result.stretch,
                                            }]
                                        });
                                    }
                                });
                                break;
                                // 插入文字結果
                            case "richText":

                                var originalCell = angular.copy(cell),
                                    content = '<p><strong><span style="font-size:48px">' + result.name + '</span></strong></p>' + result.content;

                                insertText(cell, content);
                                undoManager.add({
                                    undo: function() {
                                        angular.extend(cell, originalCell);
                                    },
                                    redo: function() {
                                        insertText(cell, content);
                                    }
                                });
                                break;
                            case "iframe":
                                var originalCell = angular.copy(cell),
                                    embed = result.embed;

                                insertEmbed(cell, embed);
                                undoManager.add({
                                    undo: function() {
                                        angular.extend(cell, originalCell);
                                    },
                                    redo: function() {
                                        insertEmbed(cell, embed);
                                    }
                                });
                                break;
                            case "youtube":

                                var originalCell = angular.copy(cell),
                                    name = result.name,
                                    videoID = result.videoID,
                                    url = result.url,
                                    belong = "youtube";

                                insertVideo(cell, belong, videoID, url, name);
                                undoManager.add({
                                    undo: function() {
                                        angular.extend(cell, originalCell);
                                    },
                                    redo: function() {
                                        insertVideo(cell, belong, videoID, url, name);
                                    }
                                });
                                break;
                        }

                    }
                });
            };


            $rootScope.removeCell = function(cell) {
                var originalCell = angular.copy(cell);
                delete cell.properties;
                angular.extend(cell, new EmptyCell(originalCell.properties));
                undoManager.add({
                    undo: function() {
                        angular.extend(cell, originalCell);
                    },
                    redo: function() {
                        angular.extend(cell, new EmptyCell(originalCell.properties));
                    }
                });
            };

            function insertAudios(cell, properties) {
                delete cell.properties;
                angular.extend(cell, new AudioCell({
                    audios: properties.audios || []
                }));
            };

            // 插入音樂
            $rootScope.insertAudios = function(cell, $files) {

                var originalCell = angular.copy(cell),
                    properties = cell.properties,
                    audios = (properties && properties.audios) || [];

                $files.forEach(function($file) {

                    var audio = {
                        name: $file.name,
                        uploading: true
                    };
                    audios.push(audio);
                    
                    Upload.upload({
                        url: 'api/cell/upload-audio',
                        data: { file: $file },
                    }).then(function(resp) {
                        audio.src = resp.data.Location;
                        audio.uploading = false;
                        delete audio.progress;
                        var idx = $rootScope.uploadQueue.indexOf(audio);
                        $rootScope.uploadQueue.splice(idx, 1);
                    }, function(resp) {
                        audio.uploading = false;
                        delete audio.progress;
                        var idx = $rootScope.uploadQueue.indexOf(audio);
                        $rootScope.uploadQueue.splice(idx, 1);
                    }, function(evt) {
                        audio.progress = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
                    });
                });

                insertAudios(cell, {
                    audios: audios
                });

                undoManager.add({
                    undo: function() {
                        angular.extend(cell, originalCell);
                    },
                    redo: function() {
                        insertAudios(cell, {
                            audios: audios
                        });
                    }
                });
            };

            function insertEmbed(cell, embed) {
                delete cell.properties;
                angular.extend(cell, new EmbedCell({
                    embed: embed
                }));
            };

            function insertVideo(cell, belong, videoID, url, name) {
                delete cell.properties;
                angular.extend(cell, new VideoCell({
                    belong: belong,
                    videoID: videoID,
                    url: url,
                    name: name
                }));
            };

            function insertImage(cell, options) {
                delete cell.properties;
                angular.extend(cell, new AlbumCell(options));
            };

            // 插入文字
            function insertText(cell, content) {
                delete cell.properties;
                angular.extend(cell, new RichTextCell({
                    html: content
                }));
            };
        }
    };
});

app.directive('pageView', function() {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/page-view.html',
        replace: false,
        link: function($scope, elem, attrs, controller) {}
    };
});

app.directive('pageThumbnail', function() {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/page-thumbnail.html',
        replace: false,
        link: function($scope, elem, attrs, controller) {}
    };
});
