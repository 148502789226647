app.directive('cellVideo', function ($rootScope){
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-video.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			$scope.replace = function () {};

			$scope.editVideo = function () {
				$rootScope.$broadcast("EDIT_VIDEO", {
					page: $scope.page,
					cell: $scope.cell
				});
			};

		}
	};
});

app.directive('cellVideoView', function ($rootScope){
	return {
		restrict: 'E',
		templateUrl: '/partials/components/cell-video-view.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
		}
	};
});

app.directive('cellVideoThumbnail', function(){
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-video-thumbnail.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {}
	};
});
