app.directive('cellAudio', function ($rootScope) {
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-audio.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
			var sortAudioPosition = {};

            var moveAudio = function(from, to) {
                var audio = $scope.cell.audios.splice(from, 1);
                $scope.cell.audios.splice(to, 0, audio[0]);
            };

            $scope.audioSortabltOptions = {
                disabled: $rootScope.isPresentationMode,
                start: function(e, ui) {
                    sortAudioPosition = {};
                    sortAudioPosition.from = ui.item.index();
                },
                stop: function(e, ui) {
                    sortAudioPosition.to = ui.item.index();
                    var to = sortAudioPosition.to,
                        from = sortAudioPosition.from;
                    undoManager.add({
                        undo: function() {
                            moveAudio(to, from);
                        },
                        redo: function() {
                            moveAudio(from, to);
                        }
                    });
                },
            };

            $scope.playListTitleChange = function(cell, oldValue) {

                var oldName = oldValue,
                    newName = cell.properties.name;

                undoManager.add({
                    undo: function() {
                        $scope.cell.properties.name = oldName;
                    },
                    redo: function() {
                        $scope.cell.properties.name = newName;
                    }
                });
            };

            $scope.rename = function (index, audio) {
                $rootScope.$broadcast("RENAME_AUDIO", {
                    cell: $scope.cell,
                    audio: audio
                })
            };

            $scope.remove = function (index, audio) {
                var originalAudio = audio;
                $scope.cell.properties.audios.splice(index, 1);
                undoManager.add({
                    undo: function() {
                        $scope.cell.properties.audios.splice(index, 0, originalAudio);
                    },
                    redo: function() {
                        $scope.cell.properties.audios.splice(index, 1);
                    }
                });
            };

            $scope.isEmptyString = function(str) {
                return !!$(str).text();
            };

		}
	};
});

app.directive('cellAudioView', function(){
    return {
        restrict: 'E',
        templateUrl: '/partials/components/cell-audio-view.html',
        replace: true,
        link: function($scope, elem, attrs, controller) {}
    };
});


app.directive('cellAudioThumbnail', function(){
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-audio-thumbnail.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {}
	};
});
