app.controller('HoverAvatarController', function ($scope, $rootScope, UserFactory, ProjectFactory, $q, $timeout) {

    var userCache = {};
    var projectsCache = {};
    $scope.user = {};
    $scope.projects = [];
    $scope.emptys = [];
    $scope.isLoading = true;

    $scope.$on("HOVER_AVATAR", function (e, userId) {
        if (!userId) return;
        $scope.isLoading = true;
        $scope.user = {};
        $scope.projects = [];
        var userPromise = loadUser(userId);
        var projectPromise = loadProjects(userId);
        $q.all([userPromise, projectPromise]).then(function () {
            $scope.isLoading = false;
        });
    });

    $scope.follow = function () {
        UserFactory.follow($scope.user._id).then(function (data) {
            var result = data.data;
            if (!result.error) {
                $scope.user.isFollow = true;
            }
        });
    };

    $scope.unfollow = function () {
        UserFactory.unfollow($scope.user._id).then(function (data) {
            var result = data.data;
            if (!result.error) {
                $scope.user.isFollow = false;
            }
        });
    };

    function loadUser (userId) {
        var defer = $q.defer();
        if (userCache[userId]) {
            $scope.user = userCache[userId];
            defer.resolve();
            return;
        }
        UserFactory.info(userId).then(function (data) {
            if (!data.data.error) {
                $scope.user = data.data;
                userCache[userId] = data.data;
            }
            defer.resolve();
        });
        return defer.promise;
    };

    function loadProjects (userId) {
        var defer = $q.defer();
        if (projectsCache[userId]) {
            $scope.projects = projectsCache[userId];
            $scope.emptys = new Array(4 - $scope.projects.length);
            defer.resolve();
            return;
        }
        ProjectFactory.getUserProjects(userId, 0, 3).then(function (data) {
            defer.resolve();
            if (!data.data.error) {
                $scope.projects = data.data;
                $scope.emptys = new Array(4 - $scope.projects.length);
                projectsCache[userId] = data.data;
            }
        });
        return defer.promise;
    };

});

app.directive('hoverAvatar', function($rootScope, $timeout) {
    return {
        restrict: 'A',
        link: function($scope, elem, attrs, controller) {

            var userId = attrs.hoverAvatar;

            elem.on("mouseover", function (event) {
                var position = $(elem).offset(),
                    x = position.left + $(elem).width() / 2,
                    y = position.top;
                $("#hover-avatar").addClass("show");
                $("#hover-avatar").css({
                    left: x,
                    top: y
                });
                $scope.$apply(function () {
                    $rootScope.$broadcast("HOVER_AVATAR", userId);
                });
            });

            elem.on("mouseout", function (event) {
                $timeout(function () {
                    $("#hover-avatar").removeClass("show");
                }, 100);
            });
        }
    };
});