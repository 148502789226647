app.directive('editEmbedModal', function($rootScope, $ngModal){
	return {
		scope: true,
		restrict: 'E',
		templateUrl: '/partials/components/edit-embed.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			$scope.page;
			$scope.cell;
			$scope.embed = "";
			$scope.$on("EDIT_EMBED", function (e, params) {
				$scope.page = params.page;
				$scope.cell = params.cell;
				$scope.embed = $scope.cell.properties.embed;
				$scope.onChange();
				$ngModal.openModal("edit-embed-modal");
			});

			$scope.keydown = function (e) {
				if(e.keyCode == 13 && e.metaKey) {
					$scope.editEmbed();
				}
				if(e.keyCode == 27) {
					close();
				}
			};

			$scope.onChange = function () {
				$scope.isIframe = $scope.embed.search(/(<iframe.+?<\/iframe>)/g) != -1;
			};

			function editEmbed (cell, embed) {
				delete cell.properties;
				angular.extend(cell, new EmbedCell({
					embed: embed
				}));
			};

			$scope.editEmbed = function () {

				var cell = $scope.cell,
					originalCell = angular.copy(cell),
					embed = $scope.embed;

				editEmbed(cell, embed);
				undoManager.add({
	                undo: function() {
	                    angular.extend(cell, originalCell);
	                },
	                redo: function() {
	                    editEmbed(cell, embed);
	                }
	            });

				close();
			};

			function close () {
				$ngModal.closeModal("edit-embed-modal");
				$scope.embed = "";
			};
		}
	};
});