app.directive('cellEnd', function($timeout, $rootScope, PageFactory, ProjectFactory, CourseFactory, UserFactory) {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/cell-end.html',
        replace: true,
        link: function($scope, elem, attrs, controller) {
            $scope.authorProjects = [];
            ProjectFactory.info($rootScope.projectId).then(function(data) {
                var result = data.data;
                if (!result.error) {
                    $scope.project = result;
                }

                UserFactory.getUser().then(function(user) {
                    if (user._id) {
                        $scope.user = user;

                    } else {
                        $scope.user = undefined;
                    }
                });

                ProjectFactory.getAuthorProjects($scope.project.author._id).then(function(data) {
                    $scope.authorProjects = data;
                });
                ProjectFactory.getFollowedProjects(0, 18, '-lastModifiedAt').then(function(data) {
                    $scope.relateProjects = data.data;
                });


                UserFactory.info($scope.project.author._id).then(function(data) {
                    if (!data.data.error) {
                        $scope.author = data.data;
                    }
                });
                $scope.isLoading = false;


            });

            $scope.follow = function() {
                UserFactory.follow($scope.author._id).then(function(data) {
                    var result = data.data;
                    if (!result.error) {
                        $scope.author.isFollow = true;
                    }
                });
            };

            $scope.unfollow = function() {
                UserFactory.unfollow($scope.author._id).then(function(data) {
                    var result = data.data;
                    if (!result.error) {
                        $scope.author.isFollow = false;
                    }
                });
            };
            $scope.like = function() {
                ProjectFactory.like($scope.project._id).then(function(data) {
                    var result = data.data;
                    if (!result.error) {
                        $scope.project.isLiked = true;
                        $scope.project.likeCount++;
                    }
                });
            };

            $scope.unlike = function() {
                ProjectFactory.unlike($scope.project._id).then(function(data) {
                    var result = data.data;
                    if (!result.error) {
                        $scope.project.isLiked = false;
                        $scope.project.likeCount--;
                    }
                });
            };
            // 複製專案
            $scope.cloneProject = function() {
                if (!UserFactory.user._id) return;
                $rootScope.$broadcast("CLONE_PROJECT", {
                    project: $scope.project,
                    callback: function(result) {
                        // 成功複製
                        $scope.cloneSuccess = true;
                        $timeout(function() {
                            $scope.cloneSuccess = false;
                        }, 4000);
                    }
                });
            };
            // 複製專案
            $scope.cloneProject = function() {
                if (!UserFactory.user._id) return;
                $rootScope.$broadcast("CLONE_PROJECT", {
                    project: $scope.project,
                    callback: function(result) {
                        // 成功複製
                        $scope.cloneSuccess = true;
                        $timeout(function() {
                            $scope.cloneSuccess = false;
                        }, 4000);
                    }
                });
            };


        }
    };
});
