
app.directive('cellAlbumPhoto', function ($rootScope, $timeout) {
	return {
		scope: {
			cell: "=cell",
			image: "=image",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-album-photo.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			$scope.options = {
                toolbar: 'full',
                toolbar_full: [
                    { name: 'styles', items: ['FontSize'] },
                    { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
                ],
                forcePasteAsPlainText: true,
                allowedContent : true,
                disableNativeSpellChecker: true,
                uiColor: '#FAFAFA',
                height: '400px',
                width: '100%',
                skin: 'office2013',
                colorButton_enableMore: false,
                colorButton_colors: 'EB4A4A,EB5E28,F6BC3A,8BC34A,4993C4,94658E,4D4D50,FFFFFF', //83CACF
                fontSize_sizes: '16/16px;18/18px;20/20px;24/24px;28/28px;32/32px;36/36px;40/40px;48/48px;54/54px;60/60px;66/66px;72/72px;80/80px;88/88px;96/96px;',
                font_names : '預設字體/inherit;新細明體/PMingLiU;微軟正黑體/Microsoft JhengHei;Arial;Times New Roman;Verdana;Abril Fatface;Aller Display;Lato;Lora;Open Sasn;Playfair Display;Raleway;Varela Round;',
                fontSize_defaultLabel: '24px',
                removePlugins: 'magicline'
            };


            $scope.richTextChange = function(cell, oldValue) {

                var oldHTML = oldValue,
                    newHTML = cell.properties.html;

                undoManager.add({
                    undo: function() {
                        $scope.cell.properties.html = oldHTML;
                    },
                    redo: function() {
                        $scope.cell.properties.html = newHTML;
                    }
                });
            };

		}
	};
});


app.directive('cellAlbumPhotoView', function ($rootScope, $timeout) {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/cell-album-photo-view.html',
        replace: true,
    }
});