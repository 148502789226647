app.directive('needLogin', function(UserFactory, $ngModal) {
	return {
		restrict: 'A',
		link: function($scope, elem, attrs, controller) {
            elem.on("click", function (e) {
                e.preventDefault();
                UserFactory.getUser().then(function () {
                    // 尚未登入
                    if (!UserFactory.user._id) {
                        $ngModal.openModal("login-modal");
                    }
                });
            });
        }
	};
});