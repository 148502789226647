app.directive('cellEmbed', function ($rootScope) {
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-embed.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
			$scope.editEmbed = function () {
				$rootScope.$broadcast("EDIT_EMBED", {
					page: $scope.page,
					cell: $scope.cell
				});
			};
		}
	};
});

app.directive('cellEmbedThumbnail', function(){
	return {
		scope: {
			cell: "=cell",
			page: "=page",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-embed-thumbnail.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
		}
	};
});