/**
 * Created by Tinny Wang on 2017/9/2.
 */
app.directive('foldableRegion', function() {
	
	var collapseClass = 'zmdi-caret-right',
		expandClass = 'zmdi-caret-down',
		handleClass = 'handle',
		handleSelector = '.' + handleClass;
	var toggleClasses = [collapseClass, expandClass].join(' ');
	
	var subRegion = '.sub-foldable-region';
	var togglePath = '.icon-folded > i';
	
	return {
		scope: {
			autoCollapse: '=autoCollapse'
		},
		restrict: 'A',
		link: function(scope, element, attrs){
			var $element = $(element);
			var hasHandle = $element.find(handleSelector).length > 0;

			$element.find(togglePath).addClass(collapseClass);

			$element.on('click', function (e) {
				if (hasHandle) {
					var $target = $(e.target);
					var handling = $target.hasClass(handleClass) || $target.parents(handleSelector).length > 0;
					
					if (!handling) return;
					
					$element.find(togglePath).toggleClass(toggleClasses);
					$element.find(subRegion).slideToggle('200', function() { });

					if(scope.autoCollapse && $('.sub-foldable-region:visible').length > 1) {
						var thisSiblings = $(this).siblings();
						thisSiblings.find(togglePath).removeClass(expandClass).addClass(collapseClass);
						thisSiblings.find(subRegion).slideUp('slow');
					}
				}
			});
		}
	}
});
