app.directive("autoFocus", function($rootScope, $timeout) {
    return {
        restrict: "A",
        link: function(scope, ele, attrs) {
            var delay = attrs.autoFocusDelay || 300;
            scope.$on(attrs.autoFocus, function(e) {
                $timeout(function() {
                    $(ele).focus();
                    $(ele).select();
                }, delay);
            })
        }
    }
})