/**
 * Created by Tinny Wang on 2017/9/8.
 */

app.controller('BlockTipController', function ($scope) {
	$scope.$on("BLOCK_TIP", function (e) {
		console.log('BLOCK_TIP');
	});

});

app.directive('blockTip', function ($rootScope, $timeout) {
	return {
		scope: true,
		restrict: 'A',
		link: function(scope, elem, attrs, controller){
			console.log('blockTip');
			console.log(attrs);
			
			elem.on("mouseover", function (event) {
				var position = $(elem).offset(),
					x = position.left + $(elem).width() / 2,
					y = position.top;
				$(".block-tip").addClass("show");
				$(".block-tip").css({
					left: x,
					top: y
				});
				scope.$apply(function () {
					$rootScope.$broadcast("BLOCK_TIP");
					console.log('mouseover block tip');
				});
			});

			elem.on("mouseout", function (event) {
				$timeout(function () {
					$(".block-tip").removeClass("show");
					console.log('mouseout block tip');
				}, 100);
			});

			elem.on('click', function (e) {
				console.log('click block tip');
			});
		},
		controller: function(){
			
		}
	};
});
