
app.directive('cellAlbumPhotoList', function ($rootScope, $timeout) {
	return {
		scope: {
			cell: "=cell",
			images: "=images",
		},
		restrict: 'E',
		templateUrl: '/partials/components/cell-album-photo-list.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {
			//-- Not use scope of images cause some angular scope side-effect bug
			$scope.curImage = $scope.cell.properties.images[0] || [];

			$scope.ckEditorOptions = {
          toolbar: 'full',
          toolbar_full: [
              { name: 'styles', items: ['FontSize'] },
              { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
          ],
          forcePasteAsPlainText: true,
          allowedContent : true,
          disableNativeSpellChecker: true,
          uiColor: '#FAFAFA',
          height: '400px',
          width: '100%',
          skin: 'office2013',
          colorButton_enableMore: false,
          colorButton_colors: 'EB4A4A,EB5E28,F6BC3A,8BC34A,4993C4,94658E,4D4D50,FFFFFF', //83CACF
          fontSize_sizes: '16/16px;18/18px;20/20px;24/24px;28/28px;32/32px;',
          font_names : '預設字體/inherit;新細明體/PMingLiU;微軟正黑體/Microsoft JhengHei;Arial;Times New Roman;Verdana;Abril Fatface;Aller Display;Lato;Lora;Open Sasn;Playfair Display;Raleway;Varela Round;',
          fontSize_defaultLabel: '24px',
          removePlugins: 'magicline'
      };


			$scope.sortableOptions = {
				cursor: 'move',
				axis: 'x',
				placeholder: 'placeholder',
				forcePlaceholderSize: true,
				tolerance: 'pointer',
				update: function(e, ui) {

					var fromIdx = ui.item.sortable.index,
							desIdx = ui.item.sortable.dropindex;

					undoManager.add({
						undo: function() {
							var image = $scope.cell.properties.images.splice(desIdx, 1)[0];
							$scope.cell.properties.images.splice(fromIdx, 0, image);
						},
						redo: function() {
							var image = $scope.cell.properties.images.splice(fromIdx, 1)[0];
							$scope.cell.properties.images.splice(desIdx, 0, image);
						}
					});
				}
			}

			$scope.selectImage = function($index) {
				var oldImage = $scope.curImage;
				$scope.curImage = $scope.cell.properties.images[$index];

				undoManager.add({
					undo: function() {
							$scope.curImage = oldImage;
					},
					redo: function() {
							$scope.curImage = $scope.cell.properties.images[$index];
					}
				});
			}

			$scope.removeImage = function($index, $event, image) {
				$event.stopPropagation();
				$scope.cell.properties.images.splice($index, 1);

				undoManager.add({
					undo: function() {
							$scope.cell.properties.images.splice($index, 0, image);
					},
					redo: function() {
							$scope.cell.properties.images.splice($index, 1);
					}
				});
			}

			$scope.changeCropType = function($event, stretch) {
				$event.stopPropagation();
				$scope.curImage.stretch = stretch;
			};

			$scope.richTextChange = function(cell, oldValue) {
					console.log(oldValue);

					var oldHTML = oldValue,
							newHTML = cell.properties.html;

					undoManager.add({
							undo: function() {
									$scope.cell.properties.html = oldHTML;
							},
							redo: function() {
									$scope.cell.properties.html = newHTML;
							}
					});
			};

		}
	};
});


app.directive('cellGelleryPhotoView', function ($rootScope, $timeout) {
    return {
        restrict: 'E',
        templateUrl: '/partials/components/cell-gellery-photo-view.html',
        replace: true,
    }
});
