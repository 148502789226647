app.directive('editLinkModal', function($rootScope, $ngModal){
	return {
		scope: true,
		restrict: 'E',
		templateUrl: '/partials/components/edit-link.html',
		replace: true,
		link: function($scope, elem, attrs, controller) {

			$scope.page;
			$scope.cell;

			$scope.$on("EDIT_LINK", function (e, params) {
				$scope.page = params.page;
				$scope.cell = params.cell;
				$scope.url = params.cell.properties.url;
				$ngModal.openModal("edit-link-modal");
			});

			$scope.keydown = function (e) {
				if(e.keyCode == 13 && e.metaKey) {
					$scope.editLink();
				}
				if(e.keyCode == 27) {
					close();
				}
			};

			function editLink (cell, url) {
				delete cell.properties;
				angular.extend(cell, new LinkCell({
					url: url
				}));
			};

			$scope.editLink = function () {
				var cell = $scope.cell,
					originalCell = angular.copy(cell),
					url = $scope.url;

				editLink(cell, url);
				undoManager.add({
	                undo: function() {
	                    angular.extend(cell, originalCell);
	                },
	                redo: function() {
	                    editLink(cell, url);
	                }
	            });
				close();
			};

			function close () {
				$ngModal.closeModal("edit-link-modal");
				$scope.link = "";
			};
		}
	};
});